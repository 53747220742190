import React from "react"
import styled from "styled-components"

const Trademark = styled.span`
  font-family: "Arial", sans-serif !important;
  vertical-align: 25%;
  font-size: 70%;
`

export const TM = () => <Trademark>™</Trademark>
